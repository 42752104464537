import { useCallback, useMemo } from "react";

// Next
import { useLocale } from "next-intl";

// Utils
import { useAppSelector } from "./store";

const locales = ["en", "es", "fr"];

type KVSTranslationValue = {
  locale: string;
  value: string;
};

type KVSTranslation = {
  key: string;
  values: KVSTranslationValue[];
};

/**
 * Custom translation hook with automatic section discovery
 * @returns Translation functions and locale information
 */
const useAppTranslations = () => {
  const locale = useLocale();

  const { user } = useAppSelector((state) => state.auth);

  const languageFile = useMemo(() => {
    try {
      return require(`../../../messages/${locale}.json`);
    } catch (error) {
      console.error(`Failed to load translations for locale: ${locale}`, error);
      return {};
    }
  }, [locale]);

  const sectionNames = useMemo(() => {
    const sections = [];
    for (const key in languageFile) {
      if (typeof languageFile[key] === "object" && languageFile[key] !== null) {
        sections.push(key);
      }
    }
    return sections;
  }, [languageFile]);

  /**
   * Gets a value from a nested object using a dot path
   * @param obj - The object to search
   * @param path - The dot path to the value
   * @returns The value if found, otherwise undefined
   */
  const getNestedValue = useCallback((obj: any, path: string) => {
    const keys = path.split(".");
    let current = obj;

    for (const key of keys) {
      if (current === undefined || current === null) return undefined;
      current = current[key];
    }

    if (typeof current === "object" && current !== null) {
      return undefined;
    }

    return current;
  }, []);

  /**
   * Searches in all sections of the JSON for a specific key
   * @param key - The key to search for
   * @returns The value of the key if found, otherwise undefined
   */
  const searchAllSections = useCallback(
    (key: string) => {
      if (!key) return undefined;

      for (const section of sectionNames) {
        if (
          languageFile[section]?.[key] !== undefined &&
          typeof languageFile[section][key] !== "object"
        ) {
          return languageFile[section][key];
        }
      }

      return undefined;
    },
    [languageFile, sectionNames]
  );

  /**
   * Translates a multi-word phrase by trying different strategies
   * @param text The text to translate
   * @returns Translated text
   */
  const translatePhrase = useCallback(
    (text: string) => {
      const words = text.split(" ");

      const phraseMatch = searchAllSections(text);
      if (phraseMatch !== undefined) {
        return phraseMatch;
      }

      if (words.length >= 2 && words.length <= 3) {
        const translations = words.map((word) => {
          const wordTranslation = searchAllSections(word);

          if (wordTranslation !== undefined) {
            return {
              word,
              translation: wordTranslation,
              translated: true,
            };
          }

          return {
            word,
            translation: word,
            translated: false,
          };
        });

        if (translations.every((t) => t.translated)) {
          return translations.map((t) => t.translation).join(" ");
        }
      }

      let translatedMessage = "";

      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const wordTranslation = searchAllSections(word);

        if (wordTranslation !== undefined) {
          translatedMessage += wordTranslation;
        } else {
          translatedMessage += word;
        }

        if (i < words.length - 1) {
          translatedMessage += " ";
        }
      }

      return translatedMessage;
    },
    [searchAllSections]
  );

  /**
   * Check if KVS data is in hierarchical format
   * @param data - The KVS data to check
   * @returns True if data is in hierarchical format, false otherwise
   */
  const isHierarchicalFormat = useCallback((data: any[]): boolean => {
    return (
      data &&
      data.length > 0 &&
      "values" in data[0] &&
      Array.isArray(data[0].values)
    );
  }, []);

  /**
   * Get translation from KVS data in either format
   * @param kvsData - The KVS data
   * @param key - The key to look for
   * @param currentLocale - The locale to look for
   * @returns The translation if found, otherwise undefined
   */
  const getKVSTranslation = useCallback(
    (
      kvsData: any[],
      key: string,
      currentLocale: string
    ): string | undefined => {
      if (!kvsData || kvsData.length === 0) return undefined;

      try {
        if (isHierarchicalFormat(kvsData)) {
          const keyEntry = kvsData.find(
            (item: KVSTranslation) => item.key === key
          );
          if (keyEntry) {
            const localeEntry = keyEntry.values.find(
              (v: KVSTranslationValue) => v.locale === currentLocale
            );
            return localeEntry?.value;
          }
        } else {
          const entry = kvsData.find(
            (item: any) => item.key === key && item.locale === currentLocale
          );
          return entry?.value;
        }
        return undefined;
      } catch (error) {
        console.error("Error getting KVS translation:", error);
        return undefined;
      }
    },
    [isHierarchicalFormat]
  );

  /**
   * Smart translation function with KVS integration and multiple fallback strategies
   * Ensures that objects are not returned where strings are expected
   * @param text - The text to translate
   * @returns Translated text
   */
  const translate = useCallback(
    (text: string) => {
      if (!text) return "";

      if (user?.translations && user?.translations?.length > 0) {
        try {
          const kvsData = user?.translations?.[0]?.value
            ? JSON.parse(user?.translations[0].value)
            : [];
          const kvsTranslation = getKVSTranslation(kvsData, text, locale);

          if (kvsTranslation) {
            return kvsTranslation;
          }
        } catch (error) {
          console.error("Error parsing KVS translations:", error);
        }
      }

      if (text.includes(".")) {
        const nestedValue = getNestedValue(languageFile, text);
        if (nestedValue !== undefined) {
          return nestedValue;
        }
      }

      if (
        languageFile[text] !== undefined &&
        typeof languageFile[text] !== "object"
      ) {
        return languageFile[text];
      }

      const sectionMatch = searchAllSections(text);
      if (sectionMatch !== undefined) {
        return sectionMatch;
      }

      if (text.includes(" ")) {
        return translatePhrase(text);
      }

      return text;
    },
    [
      languageFile,
      getNestedValue,
      searchAllSections,
      translatePhrase,
      user?.translations,
      locale,
      getKVSTranslation,
    ]
  );

  /**
   * Formats a placeholder by capitalizing the first letter and translating
   * @param placeholder - The placeholder to format
   * @returns Formatted placeholder
   */
  const formatPlaceholder = useCallback(
    (placeholder?: string) => {
      if (!placeholder) return "";

      const formattedKey =
        placeholder.charAt(0).toUpperCase() + placeholder.slice(1);
      return translate(formattedKey);
    },
    [translate]
  );

  /**
   * Get all translations for a key from KVS data in either format
   * @param kvsData - The KVS data
   * @param key - The key to look for
   * @returns Record of locale-translation pairs
   */
  const getKVSTranslationsForKey = useCallback(
    (kvsData: any[], key: string): Record<string, string> => {
      const translations: Record<string, string> = {};

      if (!kvsData || kvsData.length === 0) return translations;

      try {
        if (isHierarchicalFormat(kvsData)) {
          const keyEntry = kvsData.find(
            (item: KVSTranslation) => item.key === key
          );
          if (keyEntry) {
            keyEntry.values.forEach((v: KVSTranslationValue) => {
              translations[v.locale] = v.value;
            });
          }
        } else {
          const relevantEntries = kvsData.filter(
            (item: any) => item.key === key
          );
          relevantEntries.forEach((item: any) => {
            translations[item.locale] = item.value;
          });
        }
      } catch (error) {
        console.error("Error getting KVS translations for key:", error);
      }

      return translations;
    },
    [isHierarchicalFormat]
  );

  /**
   * Get the key and translations for a specific key in all languages or a specific language
   * @param key - The key to search for
   * @param language - The language to search for (optional)
   * @returns The translations for the key in all languages or the specific language
   */
  const getTranslationsForKey = useCallback(
    (key: string, language?: string) => {
      if (!key) return language ? "" : {};

      const translations: Record<string, string> = {};

      if (user?.translations && user?.translations.length > 0) {
        try {
          const kvsData = user?.translations?.[0]?.value
            ? JSON.parse(user?.translations[0].value)
            : [];
          const kvsTranslations = getKVSTranslationsForKey(kvsData, key);

          if (language) {
            if (kvsTranslations[language]) {
              return kvsTranslations[language];
            }
          } else {
            Object.assign(translations, kvsTranslations);

            if (locales.every((loc) => translations[loc] !== undefined)) {
              return translations;
            }
          }
        } catch (error) {
          console.error("Error parsing KVS translations:", error);
        }
      }

      if (language && language === locale) {
        if (!translations[language]) {
          for (const section of sectionNames) {
            const value = languageFile[section]?.[key];
            if (value !== undefined && typeof value !== "object") {
              return value;
            }
          }
        }
        return translations[language] || "";
      }

      try {
        for (const loc of locales) {
          if (translations[loc] !== undefined) continue;

          if (language && language !== loc) continue;

          try {
            const langFile = require(`../../../messages/${loc}.json`);

            let found = false;
            for (const section in langFile) {
              if (
                typeof langFile[section] === "object" &&
                langFile[section] !== null
              ) {
                const value = langFile[section][key];
                if (value !== undefined && typeof value !== "object") {
                  translations[loc] = value;
                  found = true;
                  break;
                }
              }
            }

            if (language && found) {
              return translations[language];
            }

            if (!found && !translations[loc]) {
              translations[loc] = "";
            }
          } catch (error) {
            console.error(
              `Failed to load translations for locale: ${loc}`,
              error
            );
            translations[loc] = "";
          }
        }

        return language ? translations[language] || "" : translations;
      } catch (error) {
        console.error("Error getting translations for key:", key, error);
        return language ? "" : {};
      }
    },
    [
      languageFile,
      sectionNames,
      locale,
      user?.translations,
      getKVSTranslationsForKey,
    ]
  );

  return {
    translate,
    formatPlaceholder,
    locale,
    locales,
    getTranslationsForKey,
  };
};

export default useAppTranslations;
